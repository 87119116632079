import React from "react";
import { Link } from "gatsby";

const NavLinks = ({ extraClassName, showCTA }) => {
  const handleDropdownStatus = (e) => {
    let clickedItem = e.currentTarget.parentNode;
    clickedItem.querySelector(".dropdown-list").classList.toggle("show");
  };
  return (
    <ul className={`main-menu__list ${extraClassName}`}>
      <li>
        <Link to="/">Home</Link>
      </li>
      <li className="dropdown">
        <Link to="/about">About</Link>
        <button aria-label="dropdown toggler" onClick={handleDropdownStatus}>
          <i className="fa fa-angle-down"></i>
        </button>
        <ul className="dropdown-list">
          <li>
            <Link to="/about">About MYLT</Link>
          </li>
          <li>
            <Link to="/about/mission">Our Mission, Vision, Values</Link>
          </li>
          <li>
            <Link to="/about/why">Why MYLT</Link>
          </li>
          <li>
            <Link to="/about/founder">Eric Jackson</Link>
          </li>
        </ul>
      </li>
      <li className="dropdown">
        <Link to="/programs">Tennis</Link>
        <button aria-label="dropdown toggler" onClick={handleDropdownStatus}>
          <i className="fa fa-angle-down"></i>
        </button>
        <ul className="dropdown-list">
          <li>
            <Link to="/programs">Programs</Link>
          </li>
          <li>
            <Link to="/programs/aakd">AAKD</Link>
          </li>
          <li>
            <Link to="/programs/pe-curriculum">PE Curriculum</Link>
          </li>
          <li>
            <Link to="/programs/after-school">After School</Link>
          </li>
          <li>
            <Link to="/services">Our Services</Link>
          </li>
        </ul>
      </li>

      <li className="dropdown">
        <Link to="/events">Events</Link>
        <button aria-label="dropdown toggler" onClick={handleDropdownStatus}>
          <i className="fa fa-angle-down"></i>
        </button>
        <ul className="dropdown-list">
          <li>
            <Link to="/events/atlanta-open-showdown">
              Atlanta Open Showdown
            </Link>
          </li>
          <li>
            <Link to="/events/aakd-us-open-nyc">AAKD US Open NYC</Link>
          </li>
        </ul>
      </li>
      <li className="dropdown">
        <Link to="/contact">Contact</Link>
        <button aria-label="dropdown toggler" onClick={handleDropdownStatus}>
          <i className="fa fa-angle-down"></i>
        </button>
        <ul className="dropdown-list">
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
          <li>
            <Link to="/contact/volunteer">Volunteer</Link>
          </li>
          <li>
            <Link to="/contact/partner">Partners</Link>
          </li>
          <li>
            <Link to="/contact/sponsors">Sponsors</Link>
          </li>
        </ul>
      </li>
      {showCTA && (
        <li className="cta">
          <Link to="/donate">Donate Now</Link>
        </li>
      )}
    </ul>
  );
};

export default NavLinks;
